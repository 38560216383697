<ngx-loading [show]="loading"></ngx-loading>
<div id="form-builder:filing-navigation" class="bg-light">

<!--     <div class="row">
        <div class="col">
            <h3>{{title}}</h3>
            <pre>Add Sections and Sub Sections for Navigation</pre>
        </div>
    </div> -->

    <button *ngIf="!disableIfRdcAdmin" mat-button id="Add Section" (click)="addParentLevel()" class="btn btn-primary customButton" [disabled]="formBuilderService.isDisableAddEditButton.getValue() || formBuilderService.isDependencyPage"><mat-icon>add</mat-icon>Add Section</button>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="bg-light">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding (click)="goToSection(node, saveDialouge)" [ngClass]="{ 'background-highlight': activeNode === node }">
      <button mat-icon-button disabled></button>{{node.name}}
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding (click)="goToSection(node, saveDialouge)" [ngClass]="{ 'background-highlight': (activeNode === node || isNewNode) }">
      <button mat-icon-button disabled></button>
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input id="editInput" matInput #itemValue placeholder="Add Name" [focusMe]="isFocus" (blur)="saveNode(node, itemValue.value, saveDialouge)" (keydown.enter)="saveNode(node, itemValue.value, saveDialouge)" required/>
      </mat-form-field>
      <button *ngIf="!disableIfRdcAdmin" mat-icon-button (click)="deleteItem(node, deleteSection)" #tooltip="matTooltip" matTooltip="Delete Subsection" [matTooltipPosition]="tooltipPosition">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding (click)="goToSection(node, saveDialouge)" [ngClass]="{ 'background-highlight': activeNode === node }">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" (click)="goToSection(node, saveDialouge)">
        <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.hasChild">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <div tabindex="0" class="cut-text" *ngIf="!node.isEditable; else editing" (keydown.enter)="goToSection(node, saveDialouge)">
        <div *ngIf="!node.hasChild"></div>
        <div id="ln:{{node.name}}" (click)="goToSection(node, saveDialouge)" #tooltip="matTooltip" matTooltip="{{node.name}}" [matTooltipPosition]="tooltipPosition">
          {{node.name}}</div>
      </div>
      <ng-template #editing >
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input id="addInput" [focusMe]="isFocus" matInput #itemValue placeholder="Add Name" value="{{node.name}}"
                 (blur)="saveNode(node, itemValue.value, saveDialouge)" (keydown.enter)="saveNode(node, itemValue.value, saveDialouge)" required/>
        </mat-form-field>
      </ng-template>
      <button *ngIf="!disableIfRdcAdmin" mat-icon-button [attr.aria-label]="'Add Under ' + node.name" (click)="addNewItem(node)" #tooltip="matTooltip" matTooltip="Add Subsection" [matTooltipPosition]="tooltipPosition" [disabled]="formBuilderService.isDisableAddEditButton.getValue() || formBuilderService.isDependencyPage">
        <mat-icon>add</mat-icon>
      </button>
      <button *ngIf="!disableIfRdcAdmin" mat-icon-button [attr.aria-label]="'Edit ' + node.name" (click)="editItem(node); editMode = !editMode" #tooltip="matTooltip" matTooltip="Edit Name" [disabled]="formBuilderService.isDisableAddEditButton.getValue() || formBuilderService.isDependencyPage" [matTooltipPosition]="tooltipPosition">
        <mat-icon>edit</mat-icon>
      </button>
      <button *ngIf="!disableIfRdcAdmin" [ngClass]="getClassNamesForDelete(node)" mat-icon-button [attr.aria-label]="'Delete ' + node.name" (click)="deleteItem(node, deleteSection)" #tooltip="matTooltip" matTooltip="Delete Subsection" [disabled]="formBuilderService.isDependencyPage" [matTooltipPosition]="tooltipPosition">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-tree-node>
  </mat-tree>
</div>

<ng-template #deleteSection>
    <div class="modal-header">
        <h3 class="modal-title">Delete Section</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelDelete()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Do you wish to continue with deleting {{deleteNode.name}}?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-link btn-sm" (click)="cancelDelete()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirmDelete()">Delete and Save</button>
    </div>
</ng-template>

<ng-template #saveDialouge>
    <div class="modal-header">
        <h3 class="modal-title">Form Builder</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelSave()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Any changes made to this section will be lost. Do you wish to proceed without saving?
      </div>
      <div class="modal-footer">
        <button id="yes" type="button" class="btn btn-link btn-sm" (click)="navigateToClickedSection()">Yes</button>
        <button id="no" type="button" class="btn btn-primary" (click)="cancelSave()">No</button>
      </div>
</ng-template>
